<template>
  <div class="section">
    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.merchant || '-' }}</td>
              <td>{{ item.name && item.name || '-' }}</td>
              <td>{{ item.related_product && item.related_product || '-' }}</td>
              <td class="">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <b-button variant="primary" size="sm"
                    @click="$router.push({ name: 'master.product.show', params: { id: item.uuid } })">
                    Lihat Detail
                  </b-button>
                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormSelect, VBToggle, BButton,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BFormSelect,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nama Toko',
          field: 'merchant',
        },
        {
          label: 'Nama Produk',
          field: 'name',
        },
        {
          label: 'Produk Terkait',
          field: 'related_product',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      items: [],
      search: '',
      filter: {
        search: '',
        sort_type: 'desc',
        per_page: 10,
      },
    }
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  height: calc(100vh - 202px) !important;
  background-color: #fff;
}
</style>
