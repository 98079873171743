<template>
  <div>
    <HeaderMasterProduk />
    <Table :result="result" :delete-data="deleteProduct" :get-data="getProduk" :edit-item="editItem"
      @filter="getFilter" />
    <Modal :form-data="formPayload" :create-item="createItem" :edit-id="editId" :edit-item="editItem"
      :update-item="updateItem" :clean-up-form="cleanUpForm" :icon-bank="iconBank" @getPayload="getPayload" />
    <ModalUploadSKU :form-upload="formUpload" @getPayloadUpload="getPayloadUpload" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { VBToggle } from "bootstrap-vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import Table from "@/components/Master/Produk/Table.vue";
import Modal from "@/components/Master/Produk/Modal.vue";
import ModalUploadSKU from "@/components/Master/Produk/UploadSKU.vue";
// import ModalAddProductEceran from '@/components/MasterProduct/Modal/ModalAddProductEceran.vue'
import HeaderMasterProduk from '@/components/Master/HeaderMasterProduk.vue'

export default {
  title() {
    return "Master Produk";
  },
  components: {
    Table,
    Modal,
    ModalUploadSKU,
    HeaderMasterProduk
  },
  computed: {
    ...mapState({
      products: (state) => state.produk.products,
    }),
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getProduk();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formUpload: {
        merchant_uuid: "",
        file: null,
      },
      formPayload: {
        name: "",
        description: "",
        logo: "",
      },
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
    };
  },
  created() {
    this.getProduk();
  },
  methods: {
    ...mapActions("produk", ["getData", "postData", "deleteData", "showData"]),

    getPayload(value) {
      this.formPayload = value;
    },
    getPayloadUpload(value) {
      this.formUpload = value;
    },
    getFilter(value) {
      this.filter = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.iconBank = null;
      this.formPayload = {
        name: "",
        description: "",
        logo: "",
      };
      this.validations = "";
    },
    cleanUpFormUpload() {
      this.formPayload = {
        merchant_uuid: "",
        file: "",
      };
      this.validations = "";
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.iconBank = item.logo_url;
      this.$store.commit(
        "bank/SET_FORM_PAYLOAD",
        (this.formPayload = {
          name: item.name,
          description: item.description,
        })
      );
      this.$bvModal.show("modal-produk");
    },
    async getProduk(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      queryParams.product_type = "merchant";
      await this.getData({
        params: queryParams,
      });
      this.result = this.products;
      this.currentPage = this.products.current_page;
      this.isLoading = false;
    },
    async createItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      const response = await this.postData({
        id: "",
        payload: form,
      });
      if (response) {
        this.isLoading = false;
        this.$bvModal.hide("modal-produk");
        this.getProduk();
        successNotification(this, "Success", "Produk berhasil di buat");
        this.cleanUpForm();
      }
    },
    async updateItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");

      const response = await this.postData({
        id: this.editId,
        payload: form,
      });
      if (response) {
        this.getProduk();
        successNotification(this, "Success", "Produk berhasil di ubah!");
        this.cleanUpForm();
        this.isLoading = false;
        this.$bvModal.hide("modal-produk");
      }
    },
    async deleteProduct(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this product?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await this.deleteData({
            id: uuid,
          });
          this.getProduk();
          this.$swal({
            icon: "success",
            title: "Success!",
            text: "Produk berhasil dihapus",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-responsive {
  height: 60.5vh;
  background-color: #fff;
}
</style>
